import { useAuthStore } from '@/modules/auth/store/auth';
import { storeToRefs } from 'pinia';
import type { User } from '../types';

export default function useUserAuth() {
  const { user, hasUpdatedProfile } = storeToRefs(useAuthStore());

  async function getSession(): Promise<void> {
    if (hasUpdatedProfile.value) {
      const { data, error } = await useFetchData<User>('auth/me', {
        method: 'GET',
      });

      if (!error.value) {
        user.value = data.value?.data || null;
      }

      hasUpdatedProfile.value = false;
    }
  }

  return {
    getSession,
  };
}
